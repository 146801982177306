import React, {MouseEventHandler, useState} from "react";
import {useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import {useRoute} from "react-router5";

import "../../Styles/TemplatesCommon.sass"
import "../../Styles/EditContractCommon.sass"
import "../../Styles/UIKit.sass"
import {CallMade, CallReceived} from "@mui/icons-material";
import {$moment} from "../../../funcs";
import ContractStatusView from "../ContractStatusView";
import {ContractActionsButton, Flow} from "../ContractActionsButton";
import {entityByKeyword, fullEntityValue} from "../../../methods/workWithEntities";
import {firstSignerTitle, targetUserTitle} from "../../../methods/workWithContract";
import {getHighlightedText} from "../../../methods/getHighlightedText";
import {DocumentType} from "../../../methods/DocumentType";
type Props = {
    contract: any,
    reload: Function,
    searchText: string,
    $store: any,
    $commitToStore: Function
}

let ContractCell: any = function ContractListView(props: Props) {
    const {contract, reload, searchText, $store, $commitToStore} = props
    const $router = useRoute();
    const documentType = contract.document_type ?? DocumentType.CONTRACT;

    function openContract(contractId: string) {
        switch (documentType) {
            case DocumentType.PACKAGE:
                console.log("packageId", contractId)
                $router.router.navigate(
                    'package',
                    {
                        packageId: contractId
                    }
                )
                break;
            case DocumentType.CONTRACT:
                console.log("contractId", contractId)
                $router.router.navigate(
                    'contract',
                    {
                        contractId: contractId
                    }
                )
                break;
        }
    }

    function contractDirection() {
        return <div>
            {contract.created_by._id == props.$store.userSession._id && <CallReceived sx={{fill: "#75809E"}}/>}
            {contract.created_by._id != props.$store.userSession._id && <CallMade sx={{fill: "#75809E"}}/>}
        </div>
    }

    function entitiesDescription() {
        const entities = contract.entities ?? []
        let notEmptyEntities = entities
            .filter((entity: any) => !!entity.value)

        if (notEmptyEntities.length > 0) {
            return <div className="ChipsContainer" style={{marginTop: "15px", width: "100%"}}>
                <div className="Body_13">
                    Поля:
                </div>
                {
                    notEmptyEntities.map((entity: any) => {
                        return makeChips(entity.keyword, fullEntityValue(entity, ", "))
                    })
                }
            </div>
        } else {
            return null
        }
    }

    function makeSignersView() {
        return <>
            {
                contract.first_signer && contract.first_signer._id != props.$store.userSession._id &&
                makeSignerView(`Данные ${firstSignerTitle(contract)}:`, contract.first_signer)
            }
            {
                contract.target_user &&
                makeSignerView(`Данные ${targetUserTitle(contract)}:`, contract.target_user)
            }
        </>
    }

    function makeSignerView(title: string, user: any) {
        return <div className="ChipsContainer" style={{marginTop: "15px", width: "100%"}}>
            <div className="Body_13">
                {title}
            </div>
            {
                user.role === "entrepreneur"
                    ? makeChips("Юр. лицо", user.law_name)
                    : makeChips("ФИО", `${user.last_name} ${user.first_name} ${user.middle_name ?? ''}`)
            }

            {
                user.phone_number &&
                makeChips("Телефон", user.additional_phone_number ?? user.phone_number)
            }

            {
                user.email &&
                makeChips("Email", user.email)
            }
        </div>
    }

    function makeChips(key: string, value: string) {
        return <div className="buttonsContainer Chips">
            <div className="Subheader_13" style={{color: "#75809E"}}>
                {key + ':'}
            </div>
            <div className="Subheader_13" style={{color: "#0E1F4D"}}>
                {getHighlightedText(value, searchText)}
            </div>
        </div>
    }

    function makeContractNumber() {
        if (contract.number && contract.number.length > 0) {
            return <span> №{getHighlightedText(contract.number, searchText)}</span>;
        }
    }

    function makeTitle() {
        return <div className="buttonsContainer" style={{alignItems: "start"}}>
            {
                contractDirection()
            }
            <div
                className="title"
            >
                {getHighlightedText(contract.name, searchText)}{makeContractNumber()} от {getHighlightedText(createdAtTitle(), searchText)}
            </div>
        </div>
    }

    function createdAtTitle() {
        let currentDate = entityByKeyword(contract.system_entities ?? [], "Текущая дата")
        if (currentDate && currentDate.value?.length > 0) {
            return currentDate.value
        }
        let createdAtDate = $moment(contract.created_at, "DD.MM.YYYY")
        if (createdAtDate.toString() === "Invalid Date" || !createdAtDate.isValid()) {
            return contract.created_at.toString()
        } else {
            return createdAtDate.format('LL')
        }
    }
    function makeActionsButton() {
        return <ContractActionsButton
            flow={Flow.InListSelect}
            contract={contract}
            reload={reload}
            onCancelContract={reload}
            $store={props.$store}
            $commitToStore={props.$commitToStore}
        />
    }

    function makePackageTag() {
        if (contract.package_name) {
            return (
                <div className="ChipsContainer" style={{ marginTop: "15px" }}>
                    <div className="buttonsContainer Chips">
                        <div className="Subheader_13" style={{ color: '#75809E' }}>
                            Входит в пакет:
                        </div>
                        <div className="Subheader_13" style={{ color: '#0E1F4D' }}>
                            {getHighlightedText(contract.package_name, searchText)}
                        </div>
                    </div>
                </div>
            );
        }

        if (documentType === DocumentType.PACKAGE && contract.contracts?.length) {
            return (
                <div className="ChipsContainer" style={{ marginTop: "15px" }}>
                    <div className="buttonsContainer Chips">
                        <div className="Subheader_13" style={{ color: '#75809E' }}>
                            Документов в пакете:
                        </div>
                        <div className="Subheader_13" style={{ color: '#0E1F4D' }}>
                            {contract.contracts.length}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    if (!props.$store.userSession) {
        return
    }
    return <div
        className="templateViewContainer"
        onClick={(e) => {
            e.stopPropagation()
            openContract(contract._id)
        }}
    >
        <div className="templateTitleContainer" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ flexGrow: 1, overflow: 'hidden' }}>
                        {makeTitle()}
                    </div>
                    <div style={{ flexShrink: 0, marginLeft: '10px' }}>
                        {makeActionsButton()}
                    </div>
                </div>
                <div style={{ marginTop: "5px", width: '100%' }}>
                    <ContractStatusView contract={contract} userSession={props.$store.userSession}/>
                </div>
                {entitiesDescription()}
                {makeSignersView()}
                {makePackageTag()}
            </div>
        </div>
    </div>
}

ContractCell = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractCell)

export default ContractCell