import React from 'react';
import { mobileMaxWidthMediaQuery } from 'methods/utils';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as Back } from 'pages/Icons/size24/Back.svg';
import { useNavigationStack } from '../hooks/useNavigationStack';

type Props = {
    onClick?: () => void;
    text?: string;
}

export const BackButton: React.FC<Props> = ({ 
    onClick,
    text = 'Назад'
}) => {
    const mobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const { goBack } = useNavigationStack();
    
    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            goBack();
        }
    };

    return (
        mobile ? (
            <button
                className="new withIcon"
                onClick={handleClick}
            >
                <div className="buttonsContainer" style={{gap: "4px"}}>
                    <Back className="smallIcon" style={{fill: "#0E1F4D"}}/>
                    <div className="Subheader_15" style={{color: "#0E1F4D", marginTop: "2px"}}>{text}</div>
                </div>
            </button>
        ) : (
            <button
                className="new bigIconed"
                onClick={handleClick}
            >
                <Back className="icon dark"/>
            </button>
        )
    );
}; 