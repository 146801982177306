import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

let SectionHeader: any = function SectionHeader(props: any) {
    let secondLayer = props.secondLayer
    let sectionId = props.sectionId
    let title = props.title
    let expanded = props.expanded
    let body = props.body

    function menuTitle() {
        return <div
            className="titleContainer"
            onClick={() => props.toggleSectionExpanded(sectionId)}
        >
            {
                props.icon
            }

            <div className="commonLabel" style={{textAlign: "left"}}>
                {title}
            </div>

            <button className="new smallIconed">
                { expanded && <ExpandLess className="icon" style={{fill: "#75809E"}} />}
                { !expanded && <ExpandMore className="icon" style={{fill: "#75809E"}} />}
            </button>
        </div>
    }

    return <div
        className={"settingsSection" + (expanded ? " expanded" : " closed") + (secondLayer ? " second" : "")}
    >
        {
            menuTitle()
        }
        {
            expanded &&
            body
        }
    </div>
}

SectionHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(SectionHeader)

export default SectionHeader
