export type EntityForm = {
    $disabled: boolean,
    isEdit: boolean,
    type: string,
    keyword: string,
    value: string,
    description: string,
    adminCanFill: boolean,
    clientCanFill: boolean,
    isRequired: boolean,
    columns: {title: string}[],
    list: string[],
    formula: string,
    needNumberToWords: boolean,
    currency?: 'RUB' | 'KZT',
    postfix: string,
    defaultValue: string,
    allowMultipleValues: boolean,
    onlyFromCamera: boolean,
    roundTo?: number
}

export function emptyEntityForm(): EntityForm {
    return {
        $disabled: false,
        isEdit: false,
        type: "Текст",
        keyword: "",
        value: "",
        description: "",
        adminCanFill: true,
        clientCanFill: false,
        isRequired: true,
        columns: [],
        list: [],
        formula: "",
        needNumberToWords: false,
        postfix: "",
        defaultValue: "",
        allowMultipleValues: false,
        onlyFromCamera: false,
        currency: 'RUB',
        roundTo: 2
    }
}

export function makeEntityFormFromEntity(entity: any): EntityForm {
    return {
        $disabled: false,
        isEdit: true,
        type: entity.type,
        keyword: entity.keyword,
        value: entity.value,
        description: entity.description,
        adminCanFill: entity.adminCanFill ?? true,
        clientCanFill: entity.clientCanFill,
        isRequired: entity.isRequired ?? true,
        columns: entity.columns,
        list: entity.list,
        formula: entity.formula ?? "",
        needNumberToWords: entity.needNumberToWords ?? false,
        postfix: entity.postfix ?? "",
        defaultValue: entity.defaultValue ?? "",
        allowMultipleValues: entity.allowMultipleValues ?? false,
        onlyFromCamera: entity.onlyFromCamera ?? false,
        currency: entity.currency ?? 'RUB',
        roundTo: entity.roundTo ?? 2
    }
}
