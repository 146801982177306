import {$moment} from "../funcs";
import dayjs from "dayjs";
import {toInteger} from "lodash";
import { create, all, e } from 'mathjs'

const config = {
    precision: 5
}
const math = create(all, config)

export function calculateFormula(template: any, entity: any) {
    let daysFormulaResult = parseDaysFormula(template, entity)
    if (daysFormulaResult) {
        return daysFormulaResult
    }

    let formula = entity.formula.toLowerCase().trim()
    formula = removeMultipleSpaces(formula)
    console.log("calculateFormula start: ", entity.keyword, "|", formula)
    let entities = [...template.entities]
    entities.sort((left: any, right: any) => {
        return right.keyword.length - left.keyword.length
    })
    for (let entityInTemplate of entities) {
        if (entityInTemplate.keyword != entity.keyword
            && parseFloat(entityInTemplate.value) !== undefined
            && entityInTemplate.type != 'Фото') {
            let kw = entityInTemplate.keyword.toLowerCase().trim()
            kw = removeMultipleSpaces(kw)
            formula = formula.replaceAll(kw, entityInTemplate.value)
            console.log("calculateFormula process entity: ", entityInTemplate.keyword, "|", formula)
        }
    }

    formula = formula.replaceAll('{{', '')
    formula = formula.replaceAll('}}', '')
    formula = formula.replaceAll(',', '.')

    console.log("calculateFormula result: ", entity.keyword, "|", formula)
    try {
        let result = math.evaluate(formula)
        return roundIfNeeded(result, parseInt(entity.roundTo))
    } catch (e) {
        return ""
    }
}

function parseDaysFormula(template: any, entity: any): string | null {
    const components = getComponentsAndOperator(entity)
    if (components.length != 3) {
        return null
    }
    const left = getEntity(components[0], template)
    const right = getEntity(components[1], template)

    if (left?.type == "Дата" && right?.type == "Дата" && components[2] == "-") {
        const leftDate = getDate(left)
        const rightDate = getDate(right)

        if (!leftDate || !rightDate || !rightDate.isValid() || !leftDate.isValid()) {
            return null
        }
        let hours = leftDate.diff(rightDate, 'hours');
        const days = Math.floor(hours / 24);

        if (isNaN(days)) {
            return null
        } else {
            return `${days}`
        }
    } else {
        return null
    }
}

function roundIfNeeded(value: number, fraction: number = 2): string {
    if (toInteger(value) == value) {
        return `${value}`
    } else {
        return Number(value.toFixed(fraction)).toString()
    }
}

function getDate(entity: any): dayjs.Dayjs | null {
    let date = $moment(entity.value, "LL")
    if (date) {
        return dayjs(date.format("MM/DD/YYYY"))
    } else {
        return null
    }
}

function getComponentsAndOperator(entity: any): string[] {
    let operators: string[] = ["-", "+", "/", "*"]
    for (let i = 0; i < operators.length; i++){
        const operator = operators[i]
        let components = entity.formula.split(operator)
        if (components.length == 2) {
            return [...components, operator]
        }
    }
    return []
}

function getEntity(keyword: string, template: any) {
    let number = parseFloat(keyword.replace(",", "."))
    if (keyword.trim().length === number.toString().length) {
        return {
            type: "Текст",
            value: number.toString()
        }
    }

    for (const entity of template.entities) {
        if (entity.keyword == keyword.trim()) {
            return entity
        }
    }
    return null
}

function removeMultipleSpaces(str: string): string {
    return str.replace(/ +(?= )/g,'')
}