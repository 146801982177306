import React, { useState } from 'react'

import "styles/components/popup.sass"
import Popup from "../../components/Popup";
import {connect} from "react-redux";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Radio from '@mui/material/Radio';
import {EntityForm} from "./EntityForm";
import {commonSelect, MenuProps, TextareaStyled} from "../Styles/CommonCSSProperties";
import {ReactComponent as CheckboxIcon} from "pages/Icons/size24/Checkbox.svg"
import {ReactComponent as CheckboxChecked} from "pages/Icons/size24/CheckboxChecked.svg"
import {ReactComponent as CheckboxDisabledChecked} from "pages/Icons/size24/CheckboxDisabledChecked.svg"
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";
import ConfirmationPopup from "../Common/ConfirmationPopup";
import {FormControlLabel, RadioGroup, Tooltip} from "@mui/material";
import {ReactComponent as RadioChecked} from "../Icons/size24/RadioChecked.svg";
import {ReactComponent as RadioUnchecked} from "../Icons/size24/RadioUnchecked.svg";
import {ReactComponent as Copy} from "../Icons/size24/Copy.svg";
import {getPostfix} from "../../methods/utils"
import notifyUser from "../../methods/notifyUser";
import {ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight} from "@mui/icons-material";
import {EntityInput} from "./EntityInput";
import {
    clientEmail,
    clientFirstName,
    clientLastName,
    clientMiddleName, clientPassportIssuedAt, clientPassportIssuedBy, clientPassportIssuedByCode, clientPassportNumber,
    clientPhoneNumber, contractNumber
} from "../../methods/SystemEntity";
import { StyledSelect } from "components/StyledSelect";

type Props = {
    template: any,
    form: EntityForm,
    close: Function,
    callback: Function,
    deleteEntities: Function,
    renameEntity: Function,
    dublicateEntity: Function,
    setOrderForEntity: Function,
    isTemplate: boolean,
    $commitToStore: any
}

let EntityFormPopup: any = function EntityFormPopup(props: Props) {
    const {template, form, close, callback, deleteEntities, renameEntity, dublicateEntity, setOrderForEntity, isTemplate} = props

    const originalOrder = form.isEdit
        ? (template.entities.findIndex((entity: any) => entity.keyword == form.keyword) ?? template.entities.length) + 1
        : template.entities.length + 1;
    const originalKeyword = form.keyword

    const [order, setOrder] = useState<number>(originalOrder)

    const [deleteEntityPopup, setDeleteEntityPopup] = useState(false)
    const [isEditingContractPopup, setIsEditingContractPopup] = useState(false)
    const [suggestPopup, setSuggestPopup] = useState<string[]>([])

    const [editedForm, setEditedForm] = useState(form)

    const [isEntityRequired, setIsEntityRequired] = useState(form.isRequired ?? true)

    const [currency, setCurrency] = useState<'RUB' | 'KZT'>(editedForm.currency ?? 'RUB');

    const entityType = [
        {
            title: "Текст",
            keyword: "text",
        },
        {
            title: "Дата",
            keyword: "date",
        },
        {
            title: "Список",
            keyword: "list",
        },
        {
            title: "Фото",
            keyword: "photo",
        },
        {
            title: "Формула",
            keyword: "formula",
        },
        // {
        //     title: "Подпись",
        //     keyword: "signature",
        // },
    ];

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const {target} = event;
        if (!target) return
        const value = target.value as string
        if (value === "Подпись") {
            setEditedForm({
                ...editedForm,
                type: value,
                adminCanFill: false,
                clientCanFill: true,
                isRequired: true,
                needNumberToWords: value == entityType[0].title ? editedForm.needNumberToWords : false,
                allowMultipleValues: false
            });
        } else {
            setEditedForm({
                ...editedForm,
                type: value,
                needNumberToWords: value == entityType[0].title ? editedForm.needNumberToWords : false
            });
        }
    };

    function allowMultipleValuesCheckbox() {
        return  <div>
            <div
                className="buttonsContainer"
                style={{marginLeft: "-10px", marginTop: "10px"}}
            >
                <Checkbox
                    icon={<CheckboxIcon className="icon"/>}
                    checkedIcon={<CheckboxChecked className="icon"/>}
                    checked={editedForm.allowMultipleValues ?? false}
                    onChange={({target: {checked}}) => {
                        setEditedForm({
                            ...editedForm,
                            allowMultipleValues: checked
                        });
                    }}
                />
                <div className="commonLabel">
                    Разрешать добавлять несколько значений в это поле?
                </div>
            </div>
        </div>
    }

    function whoCanFillRadioButtons() {
        return <div>
            <div className="commonLabel" style={{marginTop: "25px"}}>
                Кто может заполнять это поле?
            </div>
            <div
                className="buttonsContainer"
                style={{marginLeft: "-10px"}}
            >
                <Checkbox
                    disabled={adminCanFillCheckboxDisabled()}
                    icon={<CheckboxIcon className="icon"/>}
                    checkedIcon={adminCanFillCheckboxDisabled() ? <CheckboxDisabledChecked className="icon"/> : <CheckboxChecked className="icon"/>}
                    checked={editedForm.adminCanFill ?? true}
                    onChange={({target: {checked}}) => {
                        setEditedForm({
                            ...editedForm,
                            adminCanFill: checked,
                        });
                    }}
                />
                <div className="commonLabel">
                    Вы
                </div>
            </div>
            <div
                className="buttonsContainer"
                style={{marginLeft: "-10px"}}
            >
                <Checkbox
                    disabled={clientCanFillCheckboxDisabled()}
                    icon={<CheckboxIcon className="icon"/>}
                    checkedIcon={clientCanFillCheckboxDisabled() ? <CheckboxDisabledChecked className="icon"/> : <CheckboxChecked className="icon"/>}
                    checked={editedForm.clientCanFill}
                    onChange={({target: {checked}}) => {
                        setEditedForm({
                            ...editedForm,
                            clientCanFill: checked,
                        });
                    }}
                />
                <div className="commonLabel">
                    Ваш клиент
                </div>
            </div>
        </div>
    }

    function defaultValueInput() {
        return <div>
            <div className="commonLabel" style={{marginTop: "25px"}}>
                Значение по умолчанию
            </div>
            <div className="form-field">
                <EntityInput
                    entity={{
                        keyword: 'defaultValue',
                        value: editedForm.defaultValue,
                        type: editedForm.type,
                        list: editedForm.list,
                        needNumberToWords: editedForm.needNumberToWords
                    }}
                    updateEntity={(value: string) => {
                        setEditedForm({
                            ...editedForm,
                            defaultValue: value
                        })
                    }}
                />
            </div>
        </div>
    }

    function makeListInputs() {
        return <div>
            <hr className="newHR"/>
            {
                editedForm.list &&
                editedForm.list.length > 0 &&
                editedForm.list.map((value, index) => {
                    return <div style={{marginTop: "15px"}}>
                        <div className="buttonsContainer commonLabel">
                            {`Значение ${index + 1}`}
                            <button
                                className="new smallIconed"
                                onClick={() => {
                                    let newList = [...editedForm.list]
                                    newList.splice(index, 1)
                                    setEditedForm({
                                        ...editedForm,
                                        list: newList
                                    });
                                }}
                            >
                                <Delete className="icon dark"/>
                            </button>
                        </div>
                        <TextareaStyled
                            value={value}
                            placeholder={"Введите предзаданное значение"}
                            style={{
                                marginTop: "10px"
                            }}
                            onChange={({target: {value}}: any) => {
                                const list = editedForm.list;
                                list[index] = value
                                setEditedForm({
                                    ...editedForm,
                                    list: list,
                                });
                            }}
                        />
                    </div>
                })
            }
            <button
                className="new"
                style={{
                    marginTop: "15px"
                }}
                onClick={() => {
                    setEditedForm({
                        ...editedForm,
                        list: editedForm.list?.concat(
                            "",
                        ) ?? [""],
                    });
                }}
            >
                <div className="commonLabel" style={{color: "#33B5FF"}}>
                    + Добавить предзаданное значение
                </div>
            </button>
        </div>
    }

    function updateRequiredOfEntity(requiredFlag: boolean) {
        setIsEntityRequired(requiredFlag)
        setEditedForm({
            ...editedForm,
            isRequired: requiredFlag,
        })
    }

    function adminCanFillCheckboxDisabled(): boolean {
        return ((editedForm.adminCanFill ?? true) && !editedForm.clientCanFill)
    }

    function sendCallback() {
        editedForm.keyword = editedForm.keyword.trim()

        if (form.isEdit && originalKeyword != editedForm.keyword) {
            renameEntity(originalKeyword, editedForm.keyword)
        }
        if (originalOrder != order) {
            callback(editedForm)
            setOrderForEntity(editedForm.keyword, order - 1)
            return;
        }
        callback(editedForm)
    }

    function suggestSystemEntities(): string[] {
        let suggests = [
            {triggers: ['дата', 'дата договора', 'дата заключение договора'], type: 'exact', suggest: [contractNumber.keyword]},
            {triggers: ['фио'], type: 'contains', suggest: [clientFirstName.keyword, clientLastName.keyword, clientMiddleName.keyword]},
            {triggers: ['телефон'], type: 'contains', suggest: [clientPhoneNumber.id]},
            {triggers: ['email'], type: 'contains', suggest: [clientEmail.id]},
            {triggers: ['паспорт', 'кем выдан', 'дата выдачи', 'код подразделения'], type: 'contains', suggest: [clientPassportNumber.keyword, clientPassportIssuedBy.keyword, clientPassportIssuedByCode.keyword, clientPassportIssuedAt.keyword]},
        ]

        for (let suggest of suggests) {
            if (suggest.type == 'contains') {
                for (let trigger of suggest.triggers) {
                    if (editedForm.keyword.trim().toLowerCase().includes(trigger.toLowerCase())) {
                        return suggest.suggest
                    }
                }
            } else if (suggest.type == 'exact') {
                for (let trigger of suggest.triggers) {
                    if (editedForm.keyword.trim().toLowerCase() == trigger.toLowerCase()) {
                        return suggest.suggest
                    }
                }
            }
        }

        return []
    }

    function suggestTitle(): string {
        let suggests = suggestPopup.map((s) => `\n{{${s}}}`)
        let keyword = `{{${editedForm.keyword}}}`
        return `Вы уверены что хотите создать поле ${keyword}?\n\nВ системе уже есть поля которые могут вам пригодиться, например:${suggests}\n\nОстальные готовые поля вы можете посмотреть в "Автозаполняемых полях" в конце списка "Мои Поля"`
    }

    function clientCanFillCheckboxDisabled(): boolean {
        return (!(editedForm.adminCanFill ?? true) && editedForm.clientCanFill)
    }

    if (deleteEntityPopup) {
        return <ConfirmationPopup
            title={"Вы уверены, что хотите удалить это поле?"}
            onClose={() => {
                setDeleteEntityPopup(false)
            }}
            onConfirm={() => {
                setDeleteEntityPopup(false)
                deleteEntities(editedForm.keyword)
            }}
        />
    }

    if (suggestPopup.length > 0) {
        return <ConfirmationPopup
            title={suggestTitle()}
            onClose={() => {
                setSuggestPopup([])
            }}
            onConfirm={() => {
                setSuggestPopup([])
                sendCallback()
            }}
        />
    }

    if (isEditingContractPopup) {
        return <ConfirmationPopup
            title={"Ваши изменения коснуться только выставленного договора, но не шаблона. Чтобы ваши изменения применялись к другим договорам, вам нужно внести изменения в шаблон. \n\nСохранить изменения в этом договоре?"}
            onClose={() => {
                setIsEditingContractPopup(false)
            }}
            onConfirm={() => {
                setIsEditingContractPopup(false)
                sendCallback()
            }}
        />
    }

    return <Popup
        style={{zIndex: 999}}
        onClose={close}
    >
        <div>
            <div className="popup-title">
                {editedForm.isEdit
                    ? "Редактирование Поля"
                    : "Добавление нового Поля"}
            </div>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >

                <div className="titleContainer">
                    <div className="buttonsContainer" style={{backgroundColor: "#EAECF3", borderRadius: "10px", padding: "10px"}}>
                        <div className="commonSubtitle">
                            Позиция в списке полей:
                        </div>

                        <button
                            className="new smallIconed"
                            onClick={() => {

                                if (order > 1) {
                                    setOrder(order - 1)
                                }
                            }}
                        >
                            <ArrowLeft className="icon dark"/>
                        </button>

                        <div className="commonSubtitle">
                            {order}
                        </div>

                        <button
                            className="new smallIconed"
                            onClick={() => {
                                let lastIndex = form.isEdit ? template.entities.length: template.entities.length + 1
                                if (order < lastIndex) {
                                    setOrder(order + 1)
                                }
                            }}
                        >
                            <ArrowRight className="icon dark"/>
                        </button>
                    </div>
                    <div className="buttonsContainer">
                        { form.isEdit &&
                            <Tooltip arrow={true} title={"Дублировать поле"}>
                                <button
                                    className="new bigIconed"
                                    onClick={() => {
                                        dublicateEntity()
                                    }}
                                >
                                    <Copy className="icon dark"/>
                                </button>
                            </Tooltip>
                        }
                        { form.isEdit &&
                            <Tooltip arrow={true} title={"Удалить поле"}>
                                <button
                                    className="new bigIconed"
                                    onClick={() => {
                                        setDeleteEntityPopup(true)
                                    }}
                                >
                                    <Delete className="icon dark"/>
                                </button>
                            </Tooltip>
                        }
                    </div>
                </div>

                <div className="commonLabel" style={{marginTop: "30px"}}>
                    Введите тип Поля
                </div>
                <div className="form-field">
                    <StyledSelect
                        className="classicStyleMuiSelect"
                        value={editedForm.type}
                        onChange={handleChange}
                    >
                        {entityType.map(({title}) => (
                            <MenuItem key={title} value={title}>
                                {title}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </div>
                <div className="commonLabel" style={{marginTop: "25px"}}>
                    <span>Введите название Поля</span>
                    <span style={{color: 'red'}}>*</span>
                </div>
                <div className="commonSubtitle" style={{marginTop: "10px"}}>
                    Такие поля как <b>"Текущая дата", "Номер договора", ФИО, паспортные и контактные данные клиента уже созданы системой </b>. Найти их вы можете в конце списка полей в "Автозаполняемых полях"
                </div>
                <div className="form-field" id="directiveName">
                    <TextareaStyled
                        value={editedForm.keyword}
                        placeholder={"Поле"}
                        onChange={({target: {value}}: any) => {
                            // Запрещаем кавычки, фигурные скобки и множественные пробелы
                            const sanitizedValue = value
                                .replace(/['"{}]/g, '') // Удаляем кавычки и фигурные скобки
                                .replace(/\s+/g, ' '); // Заменяем множественные пробелы на один

                            // Обновляем значение только если оно не состоит из одних пробелов
                            if (sanitizedValue.trim() !== '') {
                                setEditedForm({
                                    ...editedForm,
                                    keyword: sanitizedValue,
                                });
                            } else if (value === '') {
                                // Разрешаем полностью пустое значение
                                setEditedForm({
                                    ...editedForm,
                                    keyword: '',
                                });
                            }
                        }}
                    />
                </div>
                {/*{*/}
                {/*    editedForm.type == "Текст" && (editedForm.adminCanFill ?? true) &&*/}
                {/*    <div>*/}
                {/*        <div className="commonLabel" style={{marginTop: "25px"}}>*/}
                {/*            Введите значение для вашего поля*/}
                {/*        </div>*/}
                {/*        <div className="form-field">*/}
                {/*            <TextareaStyled*/}
                {/*                value={editedForm.value}*/}
                {/*                placeholder={"Значение поля"}*/}
                {/*                onChange={({target: {value}}) => {*/}
                {/*                    setEditedForm({*/}
                {/*                        ...editedForm,*/}
                {/*                        value: value,*/}
                {/*                    })*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className="commonLabel" style={{marginTop: "15px"}}>
                    Введите подсказку для вашего Поля, если нужно.
                </div>
                <div className="form-field">
                    <TextareaStyled
                        value={editedForm.description}
                        placeholder={"Подсказка к вашему полю"}
                        onChange={({target: {value}}: any) => {
                            setEditedForm({
                                ...editedForm,
                                description: value,
                            })
                        }}
                    />
                </div>
                {
                    editedForm.type == "Формула" &&
                    <div>
                        <div className="commonLabel" style={{marginTop: "15px"}}>
                            Пример формулы: название поля * название второго поля
                        </div>
                        <div className="form-field">
                            <TextareaStyled
                                value={editedForm.formula}
                                placeholder={"Формула"}
                                onChange={({target: {value}}: any) => {
                                    setEditedForm({
                                        ...editedForm,
                                        formula: value,
                                    })
                                }}
                            />
                        </div>
                        <div className="commonLabel" style={{marginTop: "15px", marginBottom: "15px"}}>
                            Округлять до
                            <StyledSelect
                                value={editedForm.roundTo?.toString() || "2"}
                                onChange={(event) => {
                                    setEditedForm({
                                        ...editedForm,
                                        roundTo: parseInt(event.target.value as string)
                                    })
                                }}
                            >
                                {[0,1,2,3,4].map((num) => (
                                    <MenuItem key={num} value={num.toString()}>
                                        {num} знаков после запятой
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </div>
                    </div>
                }
                {
                    (["Текст", "Формула"].includes(editedForm.type)) &&
                    <div>
                        <div
                            className="buttonsContainer"
                            style={{marginLeft: "-10px"}}
                        >
                            <Checkbox
                                icon={<CheckboxIcon className="icon"/>}
                                checkedIcon={<CheckboxChecked className="icon"/>}
                                checked={editedForm.needNumberToWords ?? true}
                                onChange={({target: {checked}}) => {
                                    setEditedForm({
                                        ...editedForm,
                                        needNumberToWords: checked,
                                        currency: checked ? currency : undefined,
                                        postfix: getPostfix(editedForm.value, checked, currency),
                                    });
                                }}
                            />
                            <div className="commonLabel">
                                Добавить текстовую форму числа?
                            </div>
                        </div>
                        {editedForm.needNumberToWords && (
                            <div style={{marginLeft: "20px", marginTop: "10px"}}>
                                <RadioGroup
                                    value={currency}
                                    onChange={(e) => {
                                        const newCurrency = e.target.value as 'RUB' | 'KZT';
                                        setCurrency(newCurrency);
                                        setEditedForm({
                                            ...editedForm,
                                            currency: newCurrency,
                                            postfix: getPostfix(editedForm.value, editedForm.needNumberToWords, newCurrency),
                                        });
                                    }}
                                >
                                    <FormControlLabel
                                        value="RUB"
                                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                                        label="Рубли"
                                    />
                                    <FormControlLabel
                                        value="KZT"
                                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                                        label="Тенге"
                                    />
                                </RadioGroup>
                            </div>
                        )}
                    </div>
                }
                {
                    (["Текст", "Фото", "Дата", "Список"].includes(editedForm.type)) &&
                    allowMultipleValuesCheckbox()
                }
                {
                    editedForm.type == "Фото" &&
                    <div>
                        <div
                            className="buttonsContainer"
                            style={{marginLeft: "-10px", marginTop: "10px"}}
                        >
                            <Checkbox
                                icon={<CheckboxIcon className="icon"/>}
                                checkedIcon={<CheckboxChecked className="icon"/>}
                                checked={!editedForm.onlyFromCamera}
                                onChange={({target: {checked}}) => {
                                    setEditedForm({
                                        ...editedForm,
                                        onlyFromCamera: !checked
                                    });
                                }}
                            />
                            <div className="commonLabel">
                                Разрешать брать фото с галереи?
                            </div>
                        </div>
                    </div>
                }
                {
                    (["Текст", "Фото", "Дата", "Список"].includes(editedForm.type)) &&
                    whoCanFillRadioButtons()
                }
                {
                    (["Текст", "Фото", "Дата", "Список", "Формула"].includes(editedForm.type)) &&
                    <div>
                        <div className="commonLabel" style={{marginTop: "25px"}}>
                            Поле обязательно для заполнения?
                        </div>
                        <RadioGroup
                            defaultValue={isEntityRequired ? 'Да' : 'Нет'}
                            onChange={(e, value) => {
                                updateRequiredOfEntity(value == 'Да')
                            }}
                        >
                            <FormControlLabel
                                value={'Да'}
                                control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                                label={'Да'}
                            />
                            <FormControlLabel
                                value={'Нет'}
                                control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                                label={'Нет'}
                            />
                        </RadioGroup>
                    </div>
                }
                {
                    (["Текст", "Дата", "Список"].includes(editedForm.type)) &&
                    defaultValueInput()
                }

                {
                    editedForm.type == "Список" &&
                    makeListInputs()
                }
                <hr className="newHR"/>

                <div className="buttonsContainer _wa">
                    <button
                        className="new withTitle _wa"
                        disabled={editedForm.$disabled || editedForm.keyword.length == 0}
                        onClick={() => {
                            if (editedForm.adminCanFill && !editedForm.clientCanFill && template?.is_mass_template) {
                                props.$commitToStore({
                                    notification: {
                                        title: "При выставлении массового договора дайте возможность заполнять поле клиенту",
                                        content: null
                                    }
                                })
                                return
                            }
                            if (template) {
                                if (template.system_entities.filter((entity: any) => entity.keyword.toLowerCase() === editedForm.keyword.toLowerCase()).length > 0) {
                                    notifyUser(props, "Такое поле уже есть", "Это поле уже создано системой. Чтобы заполнить его или добавить в текст договор - найдите плашку Автозаполняемые поля в конце списка полей")
                                    return;
                                }
                                if (originalKeyword != editedForm.keyword && template.entities.filter((entity: any) => entity.keyword.toLowerCase() === editedForm.keyword.toLowerCase()).length > 0) {
                                    notifyUser(props, "Такое поле уже есть", "Введите другое название поля или отредактируйте существуюущее поле")
                                    return;
                                }

                                let suggests = suggestSystemEntities()
                                if (suggests.length > 0) {
                                    setSuggestPopup(suggests)
                                    return;
                                }
                            }

                            if (!isTemplate) {
                                setIsEditingContractPopup(true)
                            }
                            sendCallback()
                        }}
                    >
                        Сохранить
                    </button>
                    {
                        form.isEdit &&
                        <button
                            className="new bigIconed"
                            onClick={() => {
                                setDeleteEntityPopup(true)
                            }}
                        >
                            <Delete className="icon dark"/>
                        </button>
                    }
                </div>
            </form>
        </div>
    </Popup>
}

EntityFormPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EntityFormPopup)

export default EntityFormPopup
