import { addSpanTagToString} from "./addSpanTagsToBody";
import {checkInlineEntities} from "./checkInlineEntities";
import { getEntityPatterns } from "./emptyEntities";
import {getSystemEntityById, SystemEntity} from "./SystemEntity";
import {additionalValuesString, cleanKeyword, fullEntityValue} from "./workWithEntities";

export function updateEntityInEditorBySystemEntityId(id: string,  value: string, otherEntities: any[] = []) {
    let entity = getSystemEntityById(id)
    if (entity) {
        updateEntityInEditorBySystemEntity(entity, value, otherEntities)
    }
}

export function updateEntityInEditorBySystemEntity(entity: SystemEntity, value: string, otherEntities: any[] = []) {
    let entityWithValue = {
        ...entity,
        value: value
    }
    updateEntityInEditor(entityWithValue, otherEntities)

    entityWithValue.keyword = entityWithValue.id
    updateEntityInEditor(entityWithValue, otherEntities)
}

export function updateEntityInEditor(entity: any, otherEntities: any[] = []) {
    let {normalPattern, escapedPattern, rawPattern} = getEntityPatterns(entity.keyword)
    let kw = rawPattern
    for (let name of [normalPattern, escapedPattern]) {
        document.getElementsByName(name).forEach((element: HTMLElement) => {
            let value = entity.value ?? ""
            if (entity.type == "Фото") {

            } else {
                let postfix = entity.postfix ?? ""
                // value = (value.toString() ?? "").split("\n").join("<br/>");
                value = checkInlineEntities(value, kw, otherEntities)
                value += postfix
                value = value.trim()
            }
            value += additionalValuesString(entity)
            // console.log("updateEntityInEditor", kw, value, element)
            element.innerHTML = value.length == 0 ? kw : value;
        })
    }
}