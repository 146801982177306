import React, { useState, useEffect } from 'react';
import { useRoute } from 'react-router5';
import { DateRangeFilter } from '../../../components/DateRangeFilter';
import { httpClient } from '../../../funcs';
import dayjs from 'dayjs';
import { notifyAboutError } from 'methods/notifyUser';
import { connect } from 'react-redux';
import { PageLoader } from '../ContractList/PageLoader';
import { ArrowLeft } from '@mui/icons-material';
import {useMediaQuery} from "@mui/material";
import { BackButton } from '../../../components/BackButton';

let ContractStatisticsPage: any = function ContractStatisticsPage(props: any) {
    const $router = useRoute();
    const mobile = useMediaQuery('(max-width: 760px)');
    const [dateRange, setDateRange] = useState<{ from: Date | null; to: Date | null }>({
        from: null,
        to: null
    });
    const [selectedRange, setSelectedRange] = useState<string>('all');
    const [statistics, setStatistics] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const loadStatistics = async () => {
        setLoading(true);
        try {
            const params = new URLSearchParams();
            if (dateRange.from) {
                params.append('from', dayjs(dateRange.from).format('YYYY-MM-DD'));
            }
            if (dateRange.to) {
                params.append('to', dayjs(dateRange.to).format('YYYY-MM-DD'));
            }

            const { data } = await httpClient.get(`/contracts-statistics?${params.toString()}`);
            setStatistics(data);
        } catch (error) {
            notifyAboutError(props, error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadStatistics();
    }, [dateRange]);

    const StatCard = ({ title, value }: { title: string; value: number | string }) => (
        <div style={{
            background: '#FFFFFF',
            borderRadius: '8px',
            padding: '20px',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
            flex: 1,
            minWidth: mobile ? 'calc(50% - 8px)' : '200px'
        }}>
            <div style={{
                color: '#75809E',
                fontSize: '14px',
                marginBottom: '8px'
            }}>
                {title}
            </div>
            <div style={{
                color: '#0E1F4D',
                fontSize: '24px',
                fontWeight: 600
            }}>
                {value}
            </div>
        </div>
    );

    function dateRangeFilter() {
        return <DateRangeFilter
            selectedRange={selectedRange}
            dateRange={dateRange}
            height="45px"
            onChange={(range, newSelectedRange) => {
                setDateRange(range);
                setSelectedRange(newSelectedRange);
            }}
            layout={mobile ? 'stacked' : 'inline'}
        />
    }

    function backButton() {
        return <BackButton 
            onClick={() => {
                $router.router.navigate('signed-contracts')
            }}
        />
    }

    return (
        <div className="ContractStatisticsPage" style={{ padding: mobile ? '16px' : '24px' }}>
            {mobile ? (
                // Мобильная версия
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                }}>
                    <div style={{ alignSelf: 'flex-start' }}>
                        {backButton()}
                    </div>
                    <hr className="newHR" style={{ margin: '0' }}/>
                    {dateRangeFilter()}
                </div>
            ) : (
                // Десктопная версия
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    marginBottom: '24px'
                }}>
                    {backButton()}
                    {dateRangeFilter()}
                </div>
            )}

            {loading ? (
                <PageLoader/>
            ) : statistics && (
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                    marginTop: mobile ? '15px' : '0'
                }}>
                    <StatCard title="Черновики" value={statistics.drafts_count} />
                    <StatCard title="Выставлено" value={statistics.sent_count} />
                    <StatCard title="Подписано" value={statistics.signed_count} />
                    <StatCard title="Отклонено" value={statistics.declined_count} />
                    <StatCard title="Конверсия подписания" value={`${statistics.sign_rate}%`} />
                </div>
            )}
        </div>
    );
};

ContractStatisticsPage = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(ContractStatisticsPage);

export default ContractStatisticsPage; 