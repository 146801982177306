import {httpClient} from "funcs"
import React, {useEffect, useRef, useState} from "react"
import {connect} from "react-redux"

import "styles/MUI-style.sass"

import {useRoute} from "react-router5"

import {Backdrop, CircularProgress, useMediaQuery} from "@mui/material";

import "pages/Styles/EditContractCommon.sass"
import "pages/Styles/EditContractEditor.sass"
import "pages/Styles/EditContractEntities.sass"
import {notifyAboutError} from "../../../methods/notifyUser";
import {PageLoader} from "../../Contracts/ContractList/PageLoader";
import ContractFooter from "../ContractFooter";
import {Delete, Upload} from "@mui/icons-material";
import {Document, Page, pdfjs} from "react-pdf";
import {TemplateGuideView} from "../../Templates/TemplatesHelpPopup";
import CreateContractView from "../../CreateContract/CreateContractView";
import _ from "lodash";
import useUpdateTemplate from "../../../utils/hooks/template/useUpdateTemplate";
import CreatePDFHeader from "./CreatePDFHeader";
import NewContractPopup from "../NewContractPopup";
import {entityById} from "../../../methods/systemEntityValueByKeyword";
import {clientPhoneNumber} from "../../../methods/SystemEntity";
import {addEntities, clearedEntitiesTemplate, deleteEntities,} from "../../../methods/workWithEntities";
import {validateRegisterFormWhileCreatingContract} from "../../../methods/registerFormValidation";
import {defaultUserFormForContractCreating} from "../../Auth/RegisterDefaultValues";
import {trackGoal} from "../../../methods/trackGoal";
import {mobileMaxWidthMediaQuery} from "../../../methods/utils";
import DocumentCell from "../../Common/DocumentCell";
import {DocumentType} from "../../../methods/DocumentType";
import {FileDropzone} from "../../../components/FileDropzone";

let CreatePDFPage: any = function CreatePDFPage(props: any) {
    const $router = useRoute();

    const [template, setTemplate] = useState<any>(null)
    const [contractInfo, setContractInfo] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [numPages, setNumPages] = useState(0);
    const [files, setFiles] = useState<{file: File, name: string}[]>([])

    const uploadRef = useRef<HTMLInputElement>(null);
    const [isChanging, setIsChanging] = useUpdateTemplate(template, updateTemplate)

    const [dragActive, setDragActive] = useState(false);

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    // handle drag events
    const handleDrag = function(event: any) {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = function(event: any) {
        console.log("handleDrop", event.dataTransfer.files)
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files) {
            processFiles(event.dataTransfer.files)
        }
    };

    function processFiles(newFiles: FileList | null) {
        if (!newFiles || !newFiles.length) return;

        const newFilesArray = Array.from(newFiles)
            .filter(file => file.type === 'application/pdf')
            .map(file => {
                let name = file.name ?? "";
                let components = name.split(".");
                components.pop();
                name = components.join(".");
                return {file, name};
            });

        if (newFilesArray.length === 0) {
            notifyAboutError(props, new Error("Пожалуйста, выберите PDF файлы"));
            return;
        }

        let resultFiles: {file: File, name: string}[] = [...files, ...newFilesArray];
        setFiles(resultFiles);
        
        let packageName = resultFiles.length > 1
            ? `Пакет документов от ${new Date().toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'})}`
            : resultFiles[0].name
        setTemplate({...template, name: packageName});
    }

    function removeFile(index: number) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);

        let packageName = newFiles.length > 1
            ? `Пакет документов от ${new Date().toLocaleDateString()}`
            : newFiles[0].name
        setTemplate({...template, name: packageName});
    }

    async function saveContract() {
        setLoading(true)

        try {
            let validationErrors: string[] = []

            if (files.length === 0) validationErrors.push("Файл не выбран");
            if (validationErrors.length) throw new Error(validationErrors.join("\n"));

            let userFormErrors = validateRegisterFormWhileCreatingContract(
                template.system_entities,
                defaultUserFormForContractCreating(template)
            )

            if (userFormErrors.length > 0) {
                throw new Error(userFormErrors.join(".\n\n"))
            }

            trackGoal('new_pdf_contract')

            const formData = new FormData();

            if (files.length > 1) {
                formData.append('files_count', files.length.toString())
                files.forEach((contract, index) => {
                    formData.append(`files[${index}]`, contract.file, contract.name);
                    formData.append(`names[${index}]`, contract.name);
                });
            } else {
                formData.append('file', files[0].file);
                formData.append('name', files[0].name);
            }

            formData.append('system_entities', JSON.stringify(template.system_entities))
            formData.append('settings', JSON.stringify(template.settings))

            if (template.actual_user_card_id) {
                formData.append('actual_user_card_id', template.actual_user_card_id)
            }

            formData.append('name', template.name)
            
            const endpoint = files.length > 1 
                ? "/package/new-pdf-package" 
                : "/contract/new-pdf-contract";

            const {data: contractInfo} = await httpClient.post(endpoint, formData)

            setContractInfo({
                contractId: contractInfo.contract_id ?? contractInfo.package_id,
                phone_number: entityById(template.system_entities, clientPhoneNumber.id)?.value
            })
            await clearEntities()
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    async function updateTemplate() {
        setIsChanging("saving")
        try {
            await httpClient.put(`/template/${template._id}`, {
                body: '',
                body_type: 'pdf',
                name: template.name,
                comment: template.comment,
                entities: JSON.stringify([]),
                system_entities: JSON.stringify(template.system_entities),
                guides: template.guides,
                settings: JSON.stringify(template.settings)
            })
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setIsChanging('saved')
        }
    }

    // Load template function
    async function loadTemplate() {
        setLoading(true)
        try {
            const {data: templates} = await httpClient.get(`/get-pdf-templates`)

            setTemplate(templates[0]);
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    async function clearEntities() {
        setTemplate(clearedEntitiesTemplate(template))
        await updateTemplate()
    }

    function toggleGuideExpanded(guide: any) {
        let guides = template.guides
        for (let g of guides) {
            if (g == guide) {
                g.closed = !g.closed
            }
        }

        setTemplate({
            ...template,
            guides: guides
        })
        setIsChanging('debouncing')
    }

    // Load template
    useEffect(() => {
        loadTemplate();
    }, []);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    });

    function updateContractSettings(settings: any) {
        setTemplate({
            ...template,
            settings
        })

        setIsChanging('debouncing')
    }

    function getEntities(isSystem: boolean): any[] {
        let path = isSystem ? 'system_entities' : 'entities'

        return [..._.get(template, path, [])]
    }

    function _addEntities(isSystem: boolean, entities: any[]) {
        let updatedTemplate = addEntities(template, isSystem, entities)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')
    }

    function _deleteEntities(isSystem: boolean, keywords: string[]) {
        let updatedTemplate = deleteEntities(template, isSystem, keywords)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')
    }

    function makeDocumentsList() {
        return <div className="documentsListContainer">
            {files.map((fileInfo, index) => (
                <DocumentCell
                    key={index}
                    name={fileInfo.name}
                    onDelete={() => removeFile(index)}
                    index={index}
                    isLast={index === files.length - 1}
                />
            ))}
        </div>
    }

    function makeEditor() {
        return <div id="editor" style={{ position: 'relative' }}>
            {/* {files.length === 0 && makeLoadPDFView()} */}
            {makeLoadPDFView()}
            {files.length === 1 && makeDocumentViewer()}
            {files.length > 1 && makeDocumentsList()}
        </div>
    }

    function makeLoadPDFView() {
        return <div>
            <FileDropzone 
                onFilesSelect={processFiles}
                multiple={true}
                filesCount={files.length}
            />
        </div>
    }
    
    function makeFooter() {
        return <div className="editContractFooterContainer">
            <ContractFooter
                template={template}
                showTip={true}
                $store={props.$store}
                generateStamp={true}
            />
        </div>
    }

    function makeDocumentViewer() {
        const file = files[0]?.file;
        if (!file) return null;
        
        let width = mobile ? window.innerWidth - 32 : 642;
        let pages: number[] = Array.from(Array(numPages).keys());
        
        return <div style={{ position: 'relative' }}>
            <div style={{ 
                position: 'absolute',
                top: '10px',
                right: '10px',
                zIndex: 1
            }}>
                <button 
                    className="new bigIconed"
                    onClick={() => setFiles([])}
                >
                    <Delete className="icon dark"/>
                </button>
            </div>

            <Document
                onLoadSuccess={(result) => {
                    setNumPages(result.numPages)
                }}
                onLoadError={(error) => {
                    console.log("onLoadError", error)
                }}
                noData={'Не удалось отобразить PDF'}
                error={'Не удалось отобразить PDF'}
                onSourceError={(error) => {
                    console.log("onSourceError", error)
                }}
                file={file}
            >
                {
                    numPages && pages.map((i) =>
                        <div key={i} style={{marginBottom: "15px"}}>
                            <Page
                                pageNumber={i + 1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                width={width}
                            />
                        </div>
                    )
                }
            </Document>
        </div>
    }

    // Render
    return <div className="CreatePDFPage" style={{maxWidth: "1090px"}}>
        <div className="NewContractPage_actNew">
            {
                loading && !template &&
                <PageLoader />
            }
            {
                template &&
                <div>
                    <div>
                        <CreatePDFHeader
                            template={template}
                            saveContract={saveContract}
                            isChanging={isChanging}
                            saveNewName={(newName: string) => {
                                setTemplate({...template, name: newName})
                                setIsChanging("debouncing")
                            }}
                            hasFile={files.length > 0}
                            hasMultipleFiles={files.length > 1}
                            deleteFile={() => {removeFile(0)}}
                            $store={props.$store}
                        />
                    </div>
                    <div className="editContractConstructor readOnly" style={{flexDirection: mobile ? "column" : "unset"}}>
                        <section style={{maxWidth: "653px", marginTop: mobile ? "0px" : "0px"}}>
                            {
                                makeEditor()
                            }
                        </section>
                        <aside>
                            <div className={"sectionsContainer"} style={{marginTop: mobile ? "15px" : "0px"}}>
                                <TemplateGuideView
                                    template={template}
                                    toggleExpanded={toggleGuideExpanded}
                                />

                                <CreateContractView
                                    contract={template}
                                    updateActualUserCardId={(actual_user_card_id: string | null) => {
                                        setTemplate({...template, actual_user_card_id: actual_user_card_id})
                                    }}
                                    isTemplate={true}
                                    updateContractSettings={updateContractSettings}
                                    addEntities={_addEntities}
                                    deleteEntities={_deleteEntities}
                                    $store={props.$store}
                                />
                            </div>
                        </aside>
                    </div>
                </div>
            }
        </div>
        {
            contractInfo &&
            <NewContractPopup
                contractId={contractInfo.contractId}
                documentType={files.length > 1 ? DocumentType.PACKAGE : DocumentType.CONTRACT}
                shouldSign={true}
                settings={template.settings}
                templateId={template._id}
                clientPhoneNumber={contractInfo.phone_number}
                close={() => {
                    $router.router.navigate("new-contract", {act: "list"}, {reload: true})
                }}
            />
        }
        {
            <Backdrop
                sx={{ backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 999 }}
                open={loading}
            >
                <CircularProgress sx={{color: "#1FA3EE"}} />
            </Backdrop>
        }
    </div>
};

CreatePDFPage = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(CreatePDFPage)

export default CreatePDFPage
