import React from 'react';
import Popup from "./Popup";

type SecurityInfoPopupProps = {
    onClose: () => void;
}

export const SecurityInfoPopup: React.FC<SecurityInfoPopupProps> = ({ onClose }) => (
    <Popup onClose={onClose}>
        <div className="security-info-popup">
            <h3 className="H3_21">Безопасность ваших данных</h3>
            
            <div className="security-section">
                <h4 className="Subheader_17">Защита документов</h4>
                <p>
                    Фотографии паспорта будут прикреплены к договору и доступны только вам и инициатору договора.
                    Поверх документов добавляются водяные знаки, исключающие возможность использовать документ в иных целях.
                    <br />
                    <br />
                    Кроме того, вы можете запретить хранить ваши данные и подписанный документ в ОкиДоки. Соответствующие настройка доступна в конце формы подписания.
                </p>
            </div>

            <div className="security-section">
                <h4 className="Subheader_17">Оператор персональных данных</h4>
                <p>
                    ООО "ОкиДоки" является официальным оператором персональных данных
                </p>
                <a 
                    href="https://pd.rkn.gov.ru/operators-registry/operators-list/?id=63-24-021683"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button"
                >
                    Проверить в реестре операторов
                </a>
            </div>

            <div className="security-section">
                <h4 className="Subheader_17">Правовая защита</h4>
                <p>
                    Сервис ОкиДоки запатентован и защищен законодательством РФ
                </p>
                <a 
                    href="https://desktop.doki.online/patent.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button"
                >
                    Посмотреть патент
                </a>
            </div>

            {/* <div className="security-section">
                <h4 className="Subheader_17">Российское ПО</h4>
                <p>
                    ОкиДоки включен в реестр российского программного обеспечения
                </p>
                <a 
                    href="https://reestr.digital.gov.ru/search/?q=ОкиДоки"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-button"
                >
                    Проверить в реестре ПО
                </a>
            </div> */}
        </div>
    </Popup>
); 