import React, { useState } from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {connect} from "react-redux";
import {EntityInput} from "../../Entity/EntityInput";
import {ChoosePassportTypeForm, ChoosePassportTypeProps} from "./ChoosePassportTypeForm";
import {FormInput, FormInputProps} from "./FormInput";
import { SystemEntityType } from 'methods/SystemEntity';
import Popup from "components/Popup"
import { SecurityInfoPopup } from 'components/SecurityInfoPopup'

export type PassportFormProps = {
    choosePassportType?: ChoosePassportTypeProps

    number?: FormInputProps
    issuedAt?: FormInputProps
    issuedBy?: FormInputProps
    issuedByCode?: FormInputProps
    registration?: FormInputProps

    signature?: SignatureInputProps

    showPhotoDisclaimer?: boolean
    photo?: PhotoInputProps
    selfie?: PhotoInputProps
    registrationPhoto?: PhotoInputProps
    photosOnlyFromCamera?: boolean
}

export type PhotoInputProps = {
    path: string
    value: string
    keyword: string
    onChange: Function,
    onlyFromCamera?: boolean
}

export type SignatureInputProps = {
    path: string
    value: string
    keyword: string
    onChange: Function
}

export let PassportForm: any = function PassportForm({form}: {form: PassportFormProps }) {
    const [showSecurityInfo, setShowSecurityInfo] = useState(false)

    function showPhotoDisclaimer() {
        return <div>
            <div className="buttonsContainer" style={{marginTop: "15px", marginBottom: "5px"}}>
                <div className="Subheader_17">
                    Фотографии паспорта
                </div>
                <button 
                    className="new withTitle secondary small"
                    onClick={() => setShowSecurityInfo(true)}
                >
                    <div className="Body_15">
                        Это безопасно?
                    </div>
                </button>
            </div>
            <div className="commonSubtitle">
                Для подписания этого документа необходимо приложить фотографии паспорта.
            </div>
        </div>
    }

    function makeSignatureInput(signatureInputProps: SignatureInputProps) {
        return <div>
            <div className="Subheader_17" style={{marginTop: "15px", marginBottom: "5px"}}>
                {signatureInputProps.keyword}
            </div>

            <EntityInput
                entity={{
                    keyword: signatureInputProps.keyword,
                    value: signatureInputProps.value,
                    type: SystemEntityType.Signature
                }}
                updateEntity={(value: string) => {
                    signatureInputProps.onChange(value)
                }}
            />
        </div>
    }


    function makePhotoInput(photoInputProps: PhotoInputProps) {
        return <div>
            <div className="Subheader_17" style={{marginTop: "15px", marginBottom: "5px"}}>
                {photoInputProps.keyword}
            </div>
            <EntityInput
                entity={{
                    keyword: photoInputProps.keyword,
                    value: photoInputProps.value,
                    type: SystemEntityType.Photo,
                    onlyFromCamera: photoInputProps.onlyFromCamera
                }}
                updateEntity={(value: string) => {
                    photoInputProps.onChange(value)
                }}
            />
        </div>
    }

    return <div>
        <div>
            <div className="Subheader_17" style={{marginTop: "20px"}}>
                Паспортные данные:
            </div>
            {
                form.choosePassportType &&
                <ChoosePassportTypeForm form={form.choosePassportType}/>
            }

            {
                form.number &&
                <FormInput props={form.number}/>
            }

            {
                form.issuedAt &&
                <FormInput props={form.issuedAt}/>
            }

            {
                form.issuedByCode &&
                <FormInput props={form.issuedByCode}/>
            }

            {
                form.issuedBy &&
                <FormInput props={form.issuedBy}/>
            }

            {
                form.registration &&
                <FormInput props={form.registration}/>
            }

            {
                form.signature &&
                makeSignatureInput(form.signature)
            }

            {
                form.showPhotoDisclaimer == true && (form.photo || form.selfie || form.registrationPhoto) &&
                showPhotoDisclaimer()
            }
            {
                form.photo &&
                makePhotoInput(form.photo)
            }

            {
                form.selfie &&
                makePhotoInput(form.selfie)
            }

            {
                form.registrationPhoto &&
                makePhotoInput(form.registrationPhoto)
            }
        </div>

        {showSecurityInfo && <SecurityInfoPopup onClose={() => setShowSecurityInfo(false)} />}
    </div>
}

PassportForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(PassportForm)
