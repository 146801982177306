import React from 'react';
import { FormControl, Select, MenuItem, SelectProps } from "@mui/material";
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from "@mui/material/Select";

const CustomSelect = styled(Select)<{ height?: '45px' | '60px' }>(({ height = '60px' }) => ({
    color: '#0E1F4D',
    width: '100%',
    border: 'none',
    fontSize: '16px',
    background: '#EAECF3',
    fontStyle: 'normal',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    lineHeight: '20px',
    borderRadius: '10px',
    
    // Стили для внутреннего input
    '& .MuiSelect-select': {
        padding: height === '45px' ? '12.5px 20px !important' : '20px !important',
        paddingRight: '44px !important',
        minHeight: 'unset !important',
        height: 'unset !important',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-wrap !important',
        backgroundColor: '#EAECF3 !important',
        borderRadius: '10px !important',
    },
    
    '&:hover': {
        background: '#EAECF3',
        border: '1px solid #D4D8E1',
        '& .MuiSelect-select': {
            padding: height === '45px' ? '11.5px 19px !important' : '19px !important',
            paddingRight: '43px !important',
        },
        '& .MuiSelect-icon': {
            right: '9px',
        },
    },

    '&.Mui-focused': {
        background: 'white',
        border: '1px solid #75809E',
        '& .MuiSelect-select': {
            padding: height === '45px' ? '11.5px 19px !important' : '19px !important',
            paddingRight: '43px !important',
        },
        '& .MuiSelect-icon': {
            right: '9px',
        },
    },

    '&.Mui-disabled': {
        background: '#E0E0E0',
        color: '#898989',
        border: 'none',
    },

    '& .MuiSelect-icon': {
        color: '#75809E',
        right: '10px',
    },
}));

const CustomMenuItem = styled(MenuItem)({
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#0E1F4D',
    
    '&.Mui-selected': {
        background: '#EAECF3',
    },
    
    '&:hover': {
        background: '#F5F6F9',
    },
});

export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            marginTop: 8,
            borderRadius: 10,
            border: '1px solid #F2F2F2',
            boxShadow: '0px 14px 50px rgba(0, 0, 0, 0.05)',
        },
    },
};

interface StyledSelectProps extends Omit<SelectProps, 'variant'> {
    children: React.ReactNode;
    onChange?: (event: SelectChangeEvent<unknown>) => void;
    value?: string;
    height?: '45px' | '60px';
}

export const StyledSelect: React.FC<StyledSelectProps> = ({ children, height, ...props }) => {
    return (
        <FormControl fullWidth>
            <CustomSelect
                {...props}
                height={height}
                MenuProps={MenuProps}
                variant="standard"
                disableUnderline
            >
                {React.Children.map(children, child => {
                    if (React.isValidElement(child) && child.type === MenuItem) {
                        return <CustomMenuItem {...child.props} />;
                    }
                    return child;
                })}
            </CustomSelect>
        </FormControl>
    );
}; 