import React from "react";
import { StyledSelect } from "components/StyledSelect";
import MenuItem from "@mui/material/MenuItem";
import { allPassportTypes } from "methods/SystemEntity";

export type ChoosePassportTypeProps = {
    allowNotSelectedOptions?: boolean
    allowedPassportTypes: string[],
    client_passport_type: string,
    onChange: Function,
    error?: boolean
}

export function ChoosePassportTypeForm({ form }: { form: ChoosePassportTypeProps }) {
    const handleChange = (event: any) => {
        form.onChange(event.target.value);
    };

    const sortedPassportTypes = allPassportTypes.filter(type => 
        form.allowedPassportTypes.includes(type)
    );

    return (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <StyledSelect
                value={form.client_passport_type || ''}
                onChange={handleChange}
                displayEmpty
                error={form.error}
            >
                <MenuItem value="" disabled>
                    <span style={{ color: '#75809E' }}>
                        Выберите тип документа
                    </span>
                </MenuItem>
                {sortedPassportTypes.map((type: string) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </StyledSelect>
        </div>
    );
}