import React, {useRef} from "react"
import {Upload} from "@mui/icons-material";

type Props = {
    onFilesSelect: (files: FileList | null) => void
    accept?: string
    multiple?: boolean
    text?: string
    buttonText?: string
    filesCount?: number
}

export const FileDropzone = ({
    onFilesSelect,
    accept = ".pdf",
    multiple = false,
    text = "Перетащите сюда файлы или воспользуйтесь кнопкой",
    buttonText,
    filesCount = 0
}: Props) => {
    const uploadRef = useRef<HTMLInputElement>(null)
    const [dragActive, setDragActive] = React.useState(false)

    const handleDrag = (e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else {
            setDragActive(false)
        }
    }

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files) {
            onFilesSelect(e.dataTransfer.files)
        }
    }

    const dropAreaContent = () => {
        return <div>
            <input
                type="file"
                style={{display: "none"}}
                ref={uploadRef}
                accept={accept}
                multiple={multiple}
                onChange={({target: {files}}) => {
                    onFilesSelect(files)
                }}
            />
            <div className={"commonSubtitle"}
                 style={{textAlign: "center", marginBottom: "15px", pointerEvents: "none"}}>
                {text}
            </div>
            <button
                className="new withTitle secondary _wa"
                style={{pointerEvents: "none"}}
            >
                <div
                    className="buttonsContainer"
                    style={{justifyContent: "center"}}
                >
                    <Upload className="icon" style={{fill: "#0E1F4D"}}/>
                    <span>
                        {buttonText || (filesCount > 0 
                            ? "Добавить еще файлы в пакет документов"
                            : "Выбрать файлы"
                        )}
                    </span>
                </div>
            </button>
        </div>
    }

    return (
        <div>
            <div
                onDragEnter={handleDrag}
                onDragExit={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                style={{
                    border: dragActive ? "1px dashed #75809E" : "1px dashed #D4D8E1",
                    borderRadius: "20px",
                    padding: "50px",
                    marginBottom: "15px"
                }}
            >
                {
                    dragActive && dropAreaContent()
                }
                {
                    !dragActive &&
                    <div
                        onClick={(e) => {
                            uploadRef.current?.click()
                        }}
                    >
                        {dropAreaContent()}
                    </div>
                }
            </div>
        </div>
    )
} 