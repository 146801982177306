import React, { useEffect, useState } from 'react';
import Popup from "../../../components/Popup";
import { FormControl, Select, MenuItem, ListItemText, useMediaQuery } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { commonSelect, MenuProps } from "../../Styles/CommonCSSProperties";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { httpClient } from 'funcs';
import { mobileMaxWidthMediaQuery } from 'methods/utils';
import { DateRangeFilter } from 'components/DateRangeFilter';
import { StyledSelect } from "components/StyledSelect";

type FiltersPopupProps = {
    onClose: () => void,
    $store: any,
    $commitToStore: (data: any) => void,
}

function FiltersPopup({
    onClose,
    $store,
    $commitToStore
}: FiltersPopupProps) {
    const [localStatusFilter, setLocalStatusFilter] = useState($store.contractsChosenStatuses ?? []);
    const [localDateRange, setLocalDateRange] = useState($store.contractsDateRange ?? { from: null, to: null });
    const [localSelectedRange, setLocalSelectedRange] = useState($store.contractsSelectedRange ?? 'all');
    const [availableContractTypes, setAvailableContractTypes] = useState<string[]>([]);

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)
    const allContractTypes = "Любой статус договора";

    useEffect(
        () => {
            loadContractTypes()
       },
       []
   )

   async function loadContractTypes() {
       const { data: contractTypes } = await httpClient.get(`/contract-types`, {})
       let types: string[] = contractTypes.map((t: any) => (t.name))
       setAvailableContractTypes(types)
   }

    function makeContractStatusFilter() {
        let filter = localStatusFilter ?? []
        let value = filter.length > 0 ? filter[0] : allContractTypes
        return (
            <div style={{ marginTop: '10px' }}>
                <div className="commonLabel" style={{ marginBottom: '4px', marginLeft: '0px' }}>
                    Статус договора
                </div>
                <StyledSelect
                    value={value}
                    onChange={(event: SelectChangeEvent<unknown>) => {
                        const {target} = event
                        if (!target) return
                        if (target.value === allContractTypes) {
                            setLocalStatusFilter([])
                        } else {
                            setLocalStatusFilter([target.value])
                        }
                    }}
                >
                    {[allContractTypes].concat(availableContractTypes).map((filter) => (
                        <MenuItem key={filter} value={filter}>
                            {filter}
                        </MenuItem>
                    ))}
                </StyledSelect>
            </div>
        )
    }

    function makeDateRangeFilter() {
        return (
            <div style={{ marginTop: '10px' }}>
                <div className="commonLabel" style={{ marginBottom: '4px', marginLeft: '0px' }}>
                    Период
                </div>
                <DateRangeFilter
                    selectedRange={localSelectedRange}
                    dateRange={localDateRange}
                    onChange={(range, selectedRange) => {
                        setLocalDateRange(range);
                        setLocalSelectedRange(selectedRange);
                    }}
                />
            </div>
        )
    }

    function clearFilters() {
        return (
            <button
                className={`new withTitle secondary ${mobile ? "_wa" : ""}`}
                onClick={() => {
                    $commitToStore({
                        contractsChosenStatuses: [],
                        contractsDateRange: { from: null, to: null },
                        contractsSelectedRange: 'all'
                    });
                    onClose();
                }}
            >
                Очистить
            </button>
        );
    }

    function cancelFilters() {
        return (
            <button
                className={`new withTitle secondary ${mobile ? "_wa" : ""}`}
                onClick={onClose}
            >
                Отмена
            </button>
        );
    }

    function applyFilters() {
        return (
            <button
                className="new withTitle primary"
                onClick={() => {
                    $commitToStore({
                        contractsChosenStatuses: localStatusFilter,
                        contractsDateRange: localDateRange,
                        contractsSelectedRange: localSelectedRange
                    });
                    onClose();
                }}
            >
                Применить
            </button>
        );
    }

    function mobileButtons() {
        return (
            <div style={{ 
                display: "flex", 
                flexDirection: "column",
                gap: "10px", 
                width: "100%"
            }}>
                <div style={{ 
                    display: "flex", 
                    gap: "10px",
                    width: "100%"
                }}>
                    {clearFilters()}
                    {cancelFilters()}
                </div>
                {applyFilters()}
            </div>
        );
    }

    function desktopButtons() {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                gap: "10px",
            }}>
                <div style={{ display: "flex", gap: "10px"}}>
                    {cancelFilters()}
                    {clearFilters()}    
                </div>
                {applyFilters()}
            </div>
        );
    }

    return (
        <Popup onClose={onClose}>
            <div>
                <h3 style={{ 
                    textAlign: "center", 
                    marginBottom: "20px",
                    fontSize: "18px",
                    fontWeight: "bold"
                }}>
                    Фильтры
                </h3>
                <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    {makeContractStatusFilter()}
                    {makeDateRangeFilter()}
                    
                    <hr className="newHR" style={{ marginTop: "0px", marginBottom: "0px" }}/>

                    {
                        mobile ? mobileButtons() : desktopButtons()
                    }
                </div>
            </div>
        </Popup>
    );
}

export default connect(
    (store: any) => ({ $store: store }),
    (dispatch) => ({ 
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) 
    })
)(FiltersPopup);