import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import "styles/pages/profile.sass"
import {httpClient} from "../../funcs";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import Popup from "../../components/Popup";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {StyledSelect} from "../../components/StyledSelect";
import {CircularProgress, MenuItem, useMediaQuery} from "@mui/material";
import {validate} from "../../validate";
import {mobileMaxWidthMediaQuery} from "../../methods/utils";
import moment from "moment";
import {FileDropzone} from "../../components/FileDropzone"


type Props = {
    close: Function,
    onCollectBonus: Function,
    $store: any,
    $commitToStore: Function
}

let RequestBillPopup: any = function RequestBillPopup(props: Props) {
    const isMobile = useMediaQuery(mobileMaxWidthMediaQuery)
    const [tariffs, setTariffs] = useState<any[]>([])
    const [selectedTariff, setSelectedTariff] = useState("")
    const [taxId, setTaxId] = useState(props.$store.userSession?.tax_id || "")
    const [email, setEmail] = useState(props.$store.userSession?.email || "")
    const [enterDataPopup, setEnterDataPopup] = useState(false)
    const [taxIdError, setTaxIdError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [activeBill, setActiveBill] = useState<any>(null)
    const [paymentProof, setPaymentProof] = useState<string>("")

    const loadTariffs = async () => {
        try {
            const {data} = await httpClient.get('/bill-tariffs')
            setTariffs(data)
            
            // Ищем годовую подписку или берем первый тариф
            const yearlyTariff = data.find((t: any) => 
                t.type === 'subscription' && t.period === 'year'
            )
            setSelectedTariff(yearlyTariff?._id || (data[0]?._id || ""))
        } catch (error) {
            console.error("Ошибка при загрузке тарифов", error)
            notifyAboutError(props, error)
        }
    }

    // Загружаем тарифы только когда открывается попап
    useEffect(() => {
        if (enterDataPopup) {
            httpClient.get('/check-bill-status')
                .then(({data}) => {
                    if (data.has_active_bill) {
                        setActiveBill(data.bill)
                    } else {
                        loadTariffs()
                    }
                })
                .catch(error => {
                    console.error("Ошибка при проверке статуса счета", error)
                    notifyAboutError(props, error)
                })
        } else {
            // Очищаем данные при закрытии попапа
            setTariffs([])
            setSelectedTariff("")
            setActiveBill(null)
            setPaymentProof("")
        }
    }, [enterDataPopup])

    // Валидация ИНН при изменении
    useEffect(() => {
        if (taxId) {  // Проверяем только если поле не пустое
            const error = validate('ruTaxId', taxId)
            setTaxIdError(error || "")
        } else {
            setTaxIdError("")
        }
    }, [taxId])

    // Валидация email при изменении
    useEffect(() => {
        if (email) {  // Проверяем только если поле не пустое
            const error = validate('email', email)
            setEmailError(error || "")
        } else {
            setEmailError("")
        }
    }, [email])

    async function requestBill() {
        try {
            setIsLoading(true)
            const tariff = tariffs.find(t => t._id === selectedTariff)
            const {data} = await httpClient.post('/request-bill', {
                'tax_id': taxId,
                'tariff_id': selectedTariff,
                'email': email,
                'type': 'bill',
                'price': tariff.price
            })
            
            // Запрашиваем статус счета
            const {data: billData} = await httpClient.get('/check-bill-status')
            if (billData.has_active_bill) {
                setActiveBill(billData.bill)
                notifyUser(props, 'Счет успешно создан', 'Загрузите платежное поручение после оплаты')
            } else {
                setEnterDataPopup(false)
                notifyUser(props, 'Счет успешно запрошен', 'Мы отправим его на указанную почту')
            }
        } catch (error) {
            notifyAboutError(props, error)
            setEnterDataPopup(false)
        } finally {
            setIsLoading(false)
        }
    }

    const handleFileUpload = async (file: any) => {
        if (file) {
            console.log("Загружаем файл", activeBill)
            const reader = new FileReader()
            reader.onloadend = async () => {
                const base64String = (reader.result as string).split(',')[1]
                setPaymentProof(base64String)
                
                try {
                    setIsLoading(true)
                    await httpClient.post('/upload-payment-proof', {
                        payment_proof: base64String,
                        bill_id: activeBill.id,
                        file_type: file.type
                    })
                    setEnterDataPopup(false)
                    notifyUser(props, 'Платежное поручение загружено', 'Тариф будет активирован после проверки')
                } catch (error) {
                    notifyAboutError(props, error)
                } finally {
                    setIsLoading(false)
                }
            }
            reader.readAsDataURL(file)
        }
    }

    // Форма валидна если:
    // 1. Выбран тариф
    // 2. ИНН не пустой и без ошибок
    // 3. Email не пустой и без ошибок
    const isFormValid = selectedTariff && 
        taxId && !taxIdError && 
        email && !emailError

    return <div className={"templateViewContainer disabledHover"}>
        <div className="commonTitle" style={{textAlign: "left"}}>
            Заказать счет на оплату по реквизитам
        </div>
        <div className="commonSubtitle" style={{textAlign: "left"}}>
            Вы можете оплатить тариф по расчетному счету
            <br/>
            Минимальная сумма оплаты - 7500 рублей
        </div>
        <hr className={"newHR"}/>
        <div className="row-data">
            <button
                className="new withTitle _wa"
                onClick={() => setEnterDataPopup(true)}
            >
                Заказать счет
            </button>
        </div>

        {
            enterDataPopup &&
            <Popup onClose={() => {setEnterDataPopup(false)}}>
                <div style={{
                    width: isMobile ? '100%' : '300px',
                    maxWidth: '100%'
                }}>
                    {activeBill ? (
                        <>
                            <div className="commonLabel" style={{marginTop: "25px", textAlign: "center"}}>
                                Ожидание оплаты
                            </div>
                            <div className="form-field">
                                <div className="commonLabel">Информация о счете</div>
                                <div>Номер счета: {activeBill.invoice_number}</div>
                                <div>Тариф: {activeBill.tariff_name}</div>
                                <div>Сумма: {activeBill.price / 100} ₽</div>
                                <div>Создан: {moment(activeBill.created_at).format('DD.MM.YYYY')}</div>
                            </div>

                            {activeBill.banking_status === 'sent' && (
                                <>
                                    <div className="form-field">
                                        <div className="commonSubtitle" style={{marginBottom: "10px"}}>
                                            Счет отправлен на указанную почту и номер телефона.
                                            Вы также можете скачать его прямо сейчас:
                                        </div>
                                        <button
                                            className="new withTitle secondary _wa"
                                            onClick={() => window.open(activeBill.pdf_url, '_blank')}
                                        >
                                            <div className="buttonsContainer" style={{justifyContent: "center"}}>
                                                <span>Скачать счет</span>
                                            </div>
                                        </button>
                                    </div>

                                    <div className="form-field" style={{marginTop: "20px"}}>
                                        <div className="commonSubtitle" style={{
                                            padding: "15px",
                                            background: "#FFF3DC",
                                            borderRadius: "10px",
                                            color: "#B38B2C"
                                        }}>
                                            <div style={{fontWeight: 600, marginBottom: "5px"}}>
                                                Важно!
                                            </div>
                                            После оплаты счета необходимо загрузить платежное поручение для подтверждения платежа.
                                            Тариф будет активирован после проверки платежного поручения.
                                        </div>
                                    </div>

                                    <hr className="newHR"/>
                                </>
                            )}
                            
                            {!activeBill.payment_proof && (
                                <div className="form-field">
                                    <div className="commonLabel" style={{marginBottom: "5px"}}>Загрузить платежное поручение</div>
                                    <FileDropzone 
                                        onFilesSelect={(files) => {
                                            if (files?.[0]) {
                                                handleFileUpload(files[0])
                                            }
                                        }}
                                        accept=".pdf,image/*"
                                        text="Перетащите сюда платежное поручение или воспользуйтесь кнопкой"
                                        buttonText="Выбрать файл"
                                    />
                                </div>
                            )}
                            
                            {activeBill.payment_proof && (
                                <div className="form-field">
                                    <div className="commonLabel" style={{color: 'green'}}>
                                        Платежное поручение загружено
                                    </div>
                                    <div>Ожидайте активации тарифа</div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="commonLabel" style={{marginTop: "25px", textAlign: "center"}}>
                                Заполните форму
                            </div>

                            <div className="form-field">
                                <div className="commonLabel">Тариф</div>
                                <StyledSelect
                                    value={selectedTariff}
                                    onChange={(e: any) => setSelectedTariff(e.target.value)}
                                    placeholder="Выберите тариф"
                                >
                                    {tariffs.map((tariff) => (
                                        <MenuItem key={tariff._id} value={tariff._id}>
                                            {tariff.name} - {tariff.price / 100} ₽
                                        </MenuItem>
                                    ))}
                                </StyledSelect>
                            </div>

                            <div className="form-field">
                                <div className="commonLabel">ИНН организации</div>
                                <TextareaStyled
                                    value={taxId}
                                    maxRows={1}
                                    error={!!taxIdError}
                                    helperText={taxIdError}
                                    onKeyPress={(e: any) => {
                                        if(e.key === 'Enter')
                                            e.preventDefault()
                                    }}
                                    placeholder={"Введите ИНН"}
                                    onChange={({target: {value}}: any) => {
                                        setTaxId(value)
                                    }}
                                />
                            </div>

                            <div className="form-field">
                                <div className="commonLabel">Email для получения счета</div>
                                <TextareaStyled
                                    value={email}
                                    maxRows={1}
                                    error={!!emailError}
                                    helperText={emailError}
                                    onKeyPress={(e: any) => {
                                        if(e.key === 'Enter')
                                            e.preventDefault()
                                    }}
                                    placeholder={"Введите email"}
                                    onChange={({target: {value}}: any) => {
                                        setEmail(value)
                                    }}
                                />
                            </div>

                            <hr className="newHR"/>

                            <button
                                className="new withTitle _wa"
                                disabled={!isFormValid || isLoading}
                                onClick={async () => {
                                    await requestBill()
                                }}
                                style={{ position: 'relative' }}
                            >
                                <div className="buttonsContainer">
                                    {isLoading && 
                                        <CircularProgress 
                                            size={20} 
                                            style={{
                                                color: 'white'
                                            }}
                                        />
                                    }
                                    <div>Запросить счет</div>
                                </div>
                            </button>
                        </>
                    )}
                </div>
            </Popup>
        }
    </div>
}

RequestBillPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(RequestBillPopup)

export default RequestBillPopup