import {connect} from "react-redux";
import React from "react";
import {IMaskInput} from "react-imask";
import {commonTextField, TextareaStyled} from "../../Styles/CommonCSSProperties";

export type FormInputProps = {
    title?: string
    placeholder: string
    tip?: string
    value: string
    disabled?: boolean
    onChange: Function
    mask?: string
    warning?: string
}

export enum FormInputPlaceholder {
    passportNumberRF = "Серия и номер паспорта",
    passportNumberForeignCountry = "Номер паспорта",
    passportIssuedAt = "Дата выдачи паспорта",
    passportIssuedByCode = "Код подразделения",
    passportIssuedBy = "Кем выдан паспорт",
    passportRegistration = "Адрес прописки",

    bankName = "Название банка",
    bankAccount = "Расчетный счет",
    corrAccount = "Корреспондентский счет",
    bic = "БИК",

    lastName = "Фамилия",
    firstName = "Имя",
    middleName = "Отчество",

    email = "E-Mail",
    phoneNumber = "Номер телефона"
}

export enum FormInputMask {
    passportNumber = "0000 000000",
    passportIssuedAt = "00.00.0000",
    passportIssuedByCode = "000-000",

    bankAccount = "00000000000000000000",
    corrAccount = "00000000000000000000",
    bic = "000000000",
    taxIdIp = "000000000000",    //12
    taxId   = "0000000000",      //10
    ogrnIp  = "000000000000000", //15
    ogrn    = "0000000000000"    //13
}


export let FormInput: any = function FormInput({props}: {props: FormInputProps }) {
    return <div style={{
        marginTop: "10px",
        minHeight: "61px",
        display: "flex",
        flexDirection: "column"
    }}>
        {
            props.mask &&
            <div className="commonTextfield">
                <IMaskInput
                    placeholder={props.placeholder}
                    type="tel"
                    style={commonTextField()}
                    value={props.value}
                    disabled={props.disabled ?? false}
                    mask={[{ mask: props.mask } ]}
                    onAccept={(value, mask) => {
                        props.onChange(value)
                    }}
                />
             </div>
        } 
        { 
            !props.mask &&
            <TextareaStyled
                id={props.placeholder}
                placeholder={props.placeholder}
                value={props.value}
                disabled={props.disabled ?? false}
                onChange={({ target: { value } }: any) => {
                    props.onChange(value)
                }}
            />
        }
    </div>
}

FormInput = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(FormInput)

