import {useRoute} from "react-router5";
import React, {useEffect, useState} from "react";
import {FormControl, Select, MenuItem, ListItemText} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";

import useInfiniteScroll from "react-infinite-scroll-hook";
import {CircularProgress, useMediaQuery} from "@mui/material";
import {connect} from "react-redux";

import "pages/Styles/TemplatesCommon.sass"
import {httpClient, nError} from "../../../funcs";
import {validate} from "../../../validate";
import ContractListHeader from "./ContractListHeader";
import ContractCell from "./ContractCell";
import notifyUser, {notifyAboutError} from "../../../methods/notifyUser";
import TabSelector from "./TabSelector";
import {ListLoader, PageLoader} from "./PageLoader";
import dayjs from "dayjs";
import { BarChart } from "@mui/icons-material";
import { notifyUserAboutCode } from "pages/Auth/DidntReceiveCode";

let ContractListPage: any = function ContractListPage(props: any) {
    const $router = useRoute();

    const mobile = useMediaQuery("(max-width: 760px)");
    const [contracts, setContracts] = useState<any[]>([])
    const [loading, setLoading] = useState(false);

    const [loadedAllContracts, setLoadedAllContracts] = useState<boolean | null>(null)
    const [pendingForLoadMoreContracts, setPendingForLoadMoreContracts] = useState(false)

    let searchQuery = props.$store.contractsSearchText ?? ''
    let chosenStatuses = props.$store.contractsChosenStatuses ?? []
    let dateRange = props.$store.contractsDateRange ?? { from: null, to: null }
    let documentType = props.$store.contractsDocumentType ?? "Договоры"

    async function loadContracts(update: boolean = false) {
        setPendingForLoadMoreContracts(true)
        setLoading(true)
        
        try {
            const len = update ? 0 : contracts.length;

            if (update) {
                setContracts([])
                setLoadedAllContracts(false)
            }

            // Общие параметры для обоих типов запросов
            const commonParams = {
                offset: len,
                contractTypesSelected: [...chosenStatuses],
                search_query: searchQuery,
                showSentContracts: props.$store.chosenDirectionTypes != "Входящие",
                dateFrom: dateRange.from ? dayjs(dateRange.from).format('YYYY-MM-DD') : undefined,
                dateTo: dateRange.to ? dayjs(dateRange.to).format('YYYY-MM-DD') : undefined
            };

            // Выполняем запрос
            const { data } = await httpClient.post  (
                documentType === "Договоры" ? "/contracts" : "/list-contract-packages/",
                commonParams
            );

            // Получаем массив элементов в зависимости от типа ответа
            const responseItems = documentType === "Договоры" ? data : data.items;

            if (!responseItems.length) return setLoadedAllContracts(true);

            const processedData = responseItems.map((item: any) => ({
                ...item,
                created_at: new Date(item.created_at),
                isPackage: documentType === "Пакеты документов"
            }));

            if (update) {
                setContracts(processedData);
            } else {
                setContracts(contracts.concat(processedData));
            }
            setPendingForLoadMoreContracts(false)
        }
        catch (error) {
            notifyAboutError(props, `Не удалось загрузить договоры. Ошибка: ${error}. Повторная попытка через 10 секунд`)
            setTimeout(() => {
                setPendingForLoadMoreContracts(false)
            }, 10000)
        }
        finally {
            setLoading(false)
        }
    }

    // Enable searching
    useEffect(() => {
        setContracts([])
        setLoadedAllContracts(false)
        loadContracts(true)
    }, [
        searchQuery, 
        props.$store.chosenDirectionTypes, 
        props.$store.contractsChosenStatuses,
        props.$store.contractsDateRange,
        props.$store.contractsDocumentType
    ])

    // Enable infinite scrolling
    const [sentryRef] = useInfiniteScroll({
        loading: pendingForLoadMoreContracts,
        hasNextPage: !loadedAllContracts,
        onLoadMore: loadContracts,
        disabled: loadedAllContracts === true
    })

    function makeStatisticsButton() {
        return <button
            className="new withTitle secondary"
            onClick={() => $router.router.navigate('contract-statistics')}
        >
            <div style={{ display: "flex", alignItems: "center", gap: "8px", color: '#75809E' }}>
                <BarChart className="icon dark" style={{fill: '#75809E'}}/>
                <span>Статистика</span>
            </div>
        </button>
    }

    // Render
    return (
        <div className="ContractListPage">
            <div style={{marginBottom:  mobile ? "95px" : "15px"}}>
                <ContractListHeader
                    searchText={searchQuery}
                    search={(query: string) => {
                        props.$commitToStore({
                            contractsSearchText: query
                        })
                    }}
                />
            </div>
            <div className="titleContainer">
                <TabSelector
                    options={["Исходящие", "Входящие"]}
                    selectedOption={props.$store.chosenDirectionTypes}
                    selectOptions={(option: string) => {
                        props.$commitToStore({
                            chosenDirectionTypes: option
                        })
                    }}
                />

                { !mobile && makeStatisticsButton()}
            </div>

            {
                loading && contracts.length == 0 &&
                <PageLoader />
            }

            <div style={{marginTop: "15px"}}>
                {
                    !!contracts.length &&
                    <div>
                        <div className="contractList">
                            {
                                contracts.map((contract, i) => (
                                    <ContractCell
                                        key={contract._id}
                                        contract={contract}
                                        reload={() => {
                                            loadContracts(true)
                                        }}
                                        searchText={searchQuery}
                                        $store={props.$store}
                                    />
                                ))
                            }
                        </div>
                        <ListLoader loading={loading}/>
                    </div>
                }
            </div>

            <div ref={sentryRef} />

            {/* When empty list */}
            {
                loadedAllContracts === true && contracts.length === 0 &&
                <div className="empty-list">
                    <div className="commonLabel">
                        У вас пока нет ни одного договора :(
                    </div>

                    <div className="commonSubtitle" style={{marginTop: "15px"}}>
                        Чтобы создать договор, вам нужно перейти в "шаблоны", выбрать нужный вам шаблон, заполнить все данные и выставить договор на подпись
                    </div>

                    <button
                        className="new withTitle"
                        style={{marginTop: "15px"}}
                        onClick={() => {
                            $router.router.navigate('main')
                        }}
                    >
                        Перейти в шаблоны
                    </button>
                </div>
            }
        </div>
    );
};

ContractListPage = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(ContractListPage)

export default ContractListPage
