import React, {useState} from "react";
import {useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import "pages/Styles/TemplatesCommon.sass"
import "pages/Styles/EditContractCommon.sass"
import {ReactComponent as Close} from "../Icons/size24/Close.svg";
import {ReactComponent as Search} from "../Icons/size24/Search.svg";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {TemplatesHelpPopup} from "./TemplatesHelpPopup";
import {QuestionMark} from "@mui/icons-material";
import {mobileMaxWidthMediaQuery} from "../../methods/utils";

type Props = {
    addTemplate: Function,
    search: Function,
    signPDF: Function,
}

let TemplatesHeader: any = function TemplatesHeader(props: Props) {
    const {addTemplate, search, signPDF} = props

    const [showSearch, setShowSearch] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    function mobileButtonsContainer() {
        return <div className="navbar" style={{padding: "20px 15px 15px 15px"}}>
            <div>
                {
                    showSearch
                        ? <div className="buttonsContainer _wa">
                            {
                                makeSearch()
                            }
                            <button
                                className="new bigIconed"
                                onClick={() => {
                                    search("")
                                    setShowSearch(false)
                                }}
                            >
                                <Close className="icon" style={{stroke: "#75809E"}} />
                            </button>
                        </div>
                        : <div className="titleContainer _wa" >
                            <div className="buttonsContainer _wa">
                                <button
                                    className="new bigIconed"
                                    onClick={() => {
                                        setShowSearch(true)
                                    }}
                                >
                                    <div>
                                        <Search className="icon"/>
                                    </div>
                                </button>
                                <button
                                    className="new bigIconed"
                                    onClick={() => {
                                        setShowHelp(true)
                                    }}
                                >
                                    <QuestionMark className={"icon dark"}/>
                                </button>
                            </div>

                            {
                                mainButtons()
                            }
                        </div>
                }
            </div>

            {/*{makeFilter({marginTop: "15px", width: "100%"})}*/}
        </div>
    }

    function desktopButtonsContainer() {
        return <div className="titleContainer">
            {makeSearch()}

            {mainButtons()}
        </div>
    }

    function mainButtons() {
        return <div className="buttonsContainer">
            <button className="new withTitle" onClick={() => {
                signPDF()
            }}>
                {
                    mobile ? "PDF" : "Подписать PDF"
                }

            </button>

            <button
                className="new withTitle"
                onClick={() => {
                    addTemplate()
                }}
            >
                Шаблоны
            </button>
        </div>
    }

    function makeSearch() {
        return <TextareaStyled
            padding={12}
            placeholder={"Поиск"}
            style={{
                maxWidth: "382px"
            }}
            onChange={({target: {value}}: any) => {
                search(value)
            }}
            onResize={undefined}
            onResizeCapture={undefined}
        />
    }

    return <div>
        {
            mobile ? mobileButtonsContainer() : desktopButtonsContainer()
        }
        {
            showHelp &&
            <TemplatesHelpPopup onClose={() => {setShowHelp(false)}}/>
        }
    </div>
}

TemplatesHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(TemplatesHeader)

export default TemplatesHeader
