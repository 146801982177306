import {useRoute} from "react-router5";
import React, {useState} from "react";
import {useMediaQuery, FormControl, Select, MenuItem, ListItemText} from "@mui/material";
import {connect} from "react-redux";
import "pages/Styles/TemplatesCommon.sass"
import "pages/Styles/EditContractCommon.sass"
import {ReactComponent as Close} from "../../Icons/size24/Close.svg";
import {ReactComponent as Search} from "../../Icons/size24/Search.svg";
import {TextareaStyled, commonSelect, MenuProps} from "../../Styles/CommonCSSProperties";
import {mobileMaxWidthMediaQuery} from "../../../methods/utils";
import {Tune} from "@mui/icons-material";
import FiltersPopup from "./FiltersPopup";
import { BarChart } from "@mui/icons-material";
import { StyledSelect } from "components/StyledSelect";

const documentTypes = ["Договоры", "Пакеты документов"];

type Props = {
    searchText: string,
    search: Function,
    $store: any,
    $commitToStore: Function,
}

function ContractListHeader({ searchText, search, $store, $commitToStore }: Props) {
    const [showSearch, setShowSearch] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const mobile = useMediaQuery(mobileMaxWidthMediaQuery);
    const $router = useRoute();

    const hasActiveFilters = () => {
        return $store.contractsChosenStatuses?.length > 0 || 
               $store.contractsDateRange?.from || 
               $store.contractsDateRange?.to
    };

    function makeDocumentTypeFilter() {
        return (
            <StyledSelect
                value={$store.contractsDocumentType ?? documentTypes[0]}
                height="45px"
                onChange={(event) => {
                    $commitToStore({
                        contractsDocumentType: event.target.value
                    });
                }}
            >
                {documentTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                        {type}
                    </MenuItem>
                ))}
            </StyledSelect>
        );
    }

    function mobileButtonsContainer() {
        const isFiltersActive = hasActiveFilters();
        return <div className="navbar" style={{padding: "20px 15px 15px 15px"}}>
            <div>
                <div className="buttonsContainer _wa">
                    {showSearch ? (
                        <>
                            {makeSearch()}
                            <button
                                className="new bigIconed"
                                onClick={() => {
                                    search("")
                                    setShowSearch(false)
                                }}
                            >
                                <Close className="icon" style={{stroke: "#75809E"}} />
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="new bigIconed"
                                onClick={() => setShowSearch(true)}
                            >
                                <Search className="icon"/>
                            </button>

                            {makeStatisticsButton()}
                            
                            <button
                                className="new bigIconed"
                                onClick={() => setShowFilters(true)}
                            >
                                <Tune className={`icon dark`} style={{fill: isFiltersActive ? '#0E1F4D' : '#75809E'}}/>
                            </button>
                            {makeDocumentTypeFilter()}
                        </>
                    )}
                </div>
            </div>
        </div>
    }

    function desktopButtonsContainer() {
        const isFiltersActive = hasActiveFilters();
        return <div className="titleContainer" style={{ gap: "10px" }}>
            {makeSearch()}
            <div className="buttonsContainer">
                {makeDocumentTypeFilter()}
                <button
                    className={`new withTitle secondary`}
                    onClick={() => setShowFilters(true)}
                    style={{ display: "flex", alignItems: "center", gap: "8px", color: isFiltersActive ? '#0E1F4D' : '#75809E' }}
                >
                    <Tune className={`icon dark`} style={{fill: isFiltersActive ? '#0E1F4D' : '#75809E'}}/>
                    Фильтры
                </button>
            </div>
        </div>
    }

    function makeSearch() {
        return <TextareaStyled
            padding={12}
            placeholder={"Поиск"}
            style={{
                maxWidth: "382px"
            }}
            value={searchText}
            onChange={({ target: { value }}: any) => {
                search(value);
            }}
            onResize={undefined}
            onResizeCapture={undefined}
        />
    }

    function makeStatisticsButton() {
        return <button
            className="new bigIconed"
            onClick={() => $router.router.navigate('contract-statistics')}
        >
            <BarChart className="icon dark" />
        </button>
    }

    return <div>
        {mobile ? mobileButtonsContainer() : desktopButtonsContainer()}
        {showFilters && <FiltersPopup onClose={() => setShowFilters(false)} />}
    </div>;
}

export default connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractListHeader)
